<template>
  <div id="mian">
    <div class="con_from">
      <div class="lineP">
        <div class="item">
          <i>商户名称</i>
          <el-input
            v-model="query.keyword"
            maxlength="128"
            suffix-icon="el-icon-search"
            placeholder="商户名称"
          ></el-input>
        </div>
        <!-- <el-select v-model="query.mchId" placeholder="请选择商户名称">
          <el-option
            style="width:200px"
            v-for="item in MerchantsDropList"
            :key="item.mchId"
            :label="item.mchName"
            :value="item.mchId">
          </el-option>
        </el-select> -->
        <div class="item itemTime">
          <i>统计日期</i>
          <el-date-picker
            v-model="query.startDate"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
          <span style="padding:0px 10px;">-</span>
          <el-date-picker
            v-model="query.endDate"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <div class="itembtn" style="width:12%;">
          <el-button class="searchBt" @click="onSearch">查询</el-button>
        </div>
      </div>
    </div>
    <div class="header">
      <span :id="active == 0 ? 'fzcolor' : ''" @click="checkActive(0)"
        >交易金额</span
      >
      <span :id="active == 1 ? 'fzcolor' : ''" @click="checkActive(1)"
        >交易笔数</span
      >
      <span :id="active == 3 ? 'fzcolor' : ''" @click="checkActive(3)"
        >退款金额</span
      >
      <span :id="active == 2 ? 'fzcolor' : ''" @click="checkActive(2)"
        >退款笔数</span
      >
      <span :id="active == 4 ? 'fzcolor' : ''" @click="checkActive(4)"
        >成功率</span
      >
      <div
        :class="{ right: true, active: timeActive == 0 ? true : false }"
        @click="checkTimeActive(0)"
      >
        近30天
      </div>
      <div
        :class="{ right: true, active: timeActive == 1 ? true : false }"
        @click="checkTimeActive(1)"
      >
        近7天
      </div>
      <!-- <div :class="{right:true, active:timeActive == 2?true:false}" @click="checkTimeActive(2)">今天</div> -->
      <div
        :class="{ right: true, active: timeActive == 3 ? true : false }"
        @click="checkTimeActive(3)"
      >
        昨天
      </div>
    </div>
    <div class="exharts" >
      <ve-line
        ref="chartline"
         width="100%"
        :data="chartData"
        :extend="chartExtend"
        :settings="active == 4 ? chartSettings : {}"
        :legend-visible="false"
      ></ve-line>
    </div>
  </div>
</template>
<script>
import { merchantsDrop } from "@/api/common.js";
import { transactionSituation } from "@/api/data/clearing.js";
import { formatDate } from "@/utils/common.js";
import { mapState, mapMutations } from "vuex"; 
export default {
  data() {
    let that = this;
    return {
      active: 0,
      timeActive: 3,
      chartData: {
        columns: ["date", "user"],
        rows: [],
      },
      chartExtend: {
        tooltip: {
          backgroundColor: "#48B8B6",
          formatter(res) {
            let data = res[0].value;
            function money(value) {
              if (!value) return "0.00";

              var intPart = Number(value) - (Number(value) % 1); //获取整数部分（这里是windy93的方法）
              var intPartFormat = intPart
                .toString()
                .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); //将整数部分逢三一断

              var floatPart = ".00"; //预定义小数部分
              var value2Array = value.toString().split(".");

              //=2表示数据有小数位
              if (value2Array.length == 2) {
                floatPart = value2Array[1].toString(); //拿到小数部分

                if (floatPart.length == 1) {
                  //补0,实际上用不着
                  return intPartFormat + "." + floatPart + "0";
                } else {
                  return intPartFormat + "." + floatPart;
                }
              } else {
                return intPartFormat + floatPart;
              }
            }
            return `${data[0]}  ${
              that.active == 0 || that.active == 3
                ? money(data[1]) + "元"
                : that.active == 1 || that.active == 2
                ? data[1] + "笔"
                : data[1] * 100 + "%"
            }`;
          },
        },
        series: {
          smooth: false,
        },
      },
      chartSettings: {
        yAxisType: ["percent"],
      },
      MerchantsDropList: [], //商户下拉表
      query: {
        startDate: formatDate(+new Date() - 24 * 60 * 60 * 1000, "yyyy-MM-dd"),
        endDate: formatDate(+new Date() - 24 * 60 * 60 * 1000, "yyyy-MM-dd"),
        mchId: "",
        keyword: "",
      },
      mainList: [],
    };
  },
  // computed: {
  //   ...mapState({
  //     platform_overview_jygk: "platform_overview_jygk",
  //   }),
  //   isCollapse(){return this.$store.state.isActiveHead;}
  // },

  // watch:{ //页面跳转时，图像没有重绘
  //   isCollapse(newValue, oldValue){
  //   this.$nextTick(() => {
  //       this.$refs.chartline.echarts.resize()
  //     })
  //   }
  // },

  created() {
    if (this.platform_overview_jygk) {
      this.query = this.platform_overview_jygk.query;
    }
    this.getLists();
    this.getMerchantsDrop();
    this.$enter(this.$route.path, this.onSearch);

    var localValue;
    this.bus.$on('isActiveHead', res => {
      this.$nextTick(() => {
        this.$refs.chartline.echarts.resize()
      })
    })

  },
  activated() {
    this.getLists();
    this.getMerchantsDrop();
  },
  methods: {
    ...mapMutations({
      platform_setoverview_jygk: "platform_setoverview_jygk",
    }),
    getLists() {
      this.query.keyword = this.query.keyword.trim();
      if (!this.query.startDate || !this.query.endDate) {
        this.$message.error("统计日期时间段必选");
        return;
      }
      this.judgeTime(this.query.startDate,this.query.endDate)
      transactionSituation(this.query)
        .then((res) => {
          if (res) {
            this.mainList = res.resultData;
            this.checkActive(this.active);
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    // 商户下拉列表
    getMerchantsDrop() {
      merchantsDrop()
        .then((res) => {
          this.MerchantsDropList = res.resultData;
        })
        .catch();
    },
    checkActive(active) {
      this.chartData.rows = [];
      this.active = active;
      let key = "";
      if (active == 0) {
        key = "tradeAmount";
      } else if (active == 1) {
        key = "tradeNum";
      } else if (active == 2) {
        key = "refundNum";
      } else if (active == 3) {
        key = "refundAmount";
      } else if (active == 4) {
        key = "successRate";
      }

      this.mainList.forEach((item) => {
        this.chartData.rows.push({
          date: item.totalDate,
          user:
            active == 0 || active == 3 || active == 4
              ? item[key] / 100
              : item[key],
        });
      });

      this.$nextTick(() => {
        this.$refs.chartline.echarts.resize()
      })
    },
    checkTimeActive(timeActive) {
      this.timeActive = timeActive;
      switch (timeActive) {
        case 0:
          this.query.startDate = formatDate(
            +new Date() - 30 * 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.query.endDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          break;
        case 1:
          this.query.startDate = formatDate(
            +new Date() - 7 * 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.query.endDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          break;
        case 2:
          this.query.startDate = formatDate(+new Date(), "yyyy-MM-dd");
          this.query.endDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case 3:
          this.query.startDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.query.endDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          break;
      }
      this.getLists();
    },
    onSearch() {
      this.getLists();
    },
    //时间范围 是否添加active判断
    judgeTime(startDate,endDate){
      if(startDate == formatDate(+new Date() - 30 * 24 * 60 * 60 * 1000,"yyyy-MM-dd") && endDate == formatDate(+new Date() - 24 * 60 * 60 * 1000,"yyyy-MM-dd")){
        this.timeActive = 0;
      }else if(startDate == formatDate(+new Date() - 7 * 24 * 60 * 60 * 1000,"yyyy-MM-dd") && endDate == formatDate(+new Date() - 24 * 60 * 60 * 1000,"yyyy-MM-dd")){
        this.timeActive = 1;
      }else if(startDate == formatDate(+new Date() - 24 * 60 * 60 * 1000,"yyyy-MM-dd") && endDate == formatDate(+new Date() - 24 * 60 * 60 * 1000,"yyyy-MM-dd")){
        this.timeActive = 3;
      }else{
        this.timeActive = null;
      }
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
    };
    this.platform_setoverview_jygk(data);
  },
};
</script>

<style scoped>
.con_from .lineP .item{
  margin-left: 6%;
}
.con_from .lineP .item:first-child {
    margin-left: 0%;
}
.header span {
  display: inline-block;
  height: 100%;
  width: 100px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}

.header {
  line-height: 40px;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid rgba(220, 224, 230, 1);
  position: relative;
  top: 0;
  margin-top:50px;
}
.header .right {
  float: right;
  width: 74px;
  height: 40px;
  color: #333333;
  font-size: 12px;
  text-align: center;
  line-height: 40px;
}

.header .right.active {
  color: #48b8b6;
  box-sizing: border-box;
}

.header span {
  cursor: pointer;
}

.header div {
  cursor: pointer;
}
</style>
